<template>
    <div class="mainPage">
        <div class="mainPageContent">
            <div class="contentPanel">
                <div class="headerLogo">
                    <img src="../assets/logo_b.png"
                         alt="Osmose"
                         @click="openDashboard()" />
                </div>
                <div class="contactSection">
                    <div class="textCenter info1 fnt24">Select Pole ID</div>
                    <div class="dFlex">
                        <div class="posrel">
                            <input type="text"
                                   placeholder="Search by Pole Id"
                                   class="searchBox"
                                   v-model="searchString" />
                            <img src="../assets/search.png" alt="delete" class="searchIcon" />
                        </div>
                        <!-- <a
                          href="javascript: void(0);"
                          :class="[polesOrderAsc ? 'ascending' : 'descending', 'sortBtn']"
                          @click="sortPoles"
                        >
                          <div class="arrow-up"></div>
                          <div class="arrow-down"></div>
                          <img src="../assets/sort2.png" alt="Sorting"/>
                        </a> -->
                        <div class="imgSort">
                            <select class="sortPI" v-model="sortParam">
                                <option>Date</option>
                                <option>Pole ID</option>
                            </select>
                            <a href="javascript: void(0);"
                               :class="[polesOrderAsc ? 'ascending' : 'descending', 'sortBtn']"
                               @click="sortPoles">
                                <img src="../assets/sort_a_1.png"
                                     class="sort_a_1"
                                     alt="Sorting" />
                                <img src="../assets/sort_d_1.png"
                                     class="sort_d_1"
                                     alt="Sorting" />
                            </a>
                        </div>
                    </div>
                    <div class="maxHgt">
                        <ul class="list-inline listInlinecst">
                            <li v-for="poleRecord in poleRecords" :key="poleRecord.poleId">
                                <a href="javascript: void(0);"
                                   @click="launchEvaluation(poleRecord)">
                                    <span>Pole ID: {{ poleRecord.poleId }}</span>
                                    <span class="fntLastdate">
                                        <strong>Last Saved Date:</strong>
                                        {{ formatUpdatedDate(poleRecord.updatedOn) }}
                                    </span>
                                </a>
                                <img src="../assets/trash.png"
                                     alt="delete"
                                     @click="showDeleteAlert(poleRecord)" />
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="textCenter">
                    <a href="javascript: void(0);"
                       class="backLink txtUppercase"
                       @click="onCancel()">
                        CANCEL
                    </a>
                </div>
            </div>

            <shared-footer></shared-footer>
            <div class="popUp" v-if="popup">
                <div class="popUpBox">
                    <p class="popupText">This action cannot be undone. Continue?</p>
                    <div class="modalFooter">
                        <button class="btnRounded" v-on:click="closePrompt()">
                            CANCEL
                        </button>
                        &nbsp;&nbsp;
                        <button class="btnRoundedFull" v-on:click="confirmPrompt()">
                            OK
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import SimpleIDB from "../api/indexedDBService.js";
    import SharedFooter from "../components/SharedFooter.vue";
    import { store } from "../store/index.js";
    import Eruda from 'eruda';

    export default {
        name: "PoleInformation",

        data: () => ({
            poles: [],
            poleRecords: [],
            popup: false,
            selectedPole: {},
            polesOrderAsc: true,
            searchString: "",
            sortParam: "Date",
        }),

        components: {
            "shared-footer": SharedFooter,
        },

        created() {
            if (localStorage.getItem("ErudaEnabled") === "Yes")
                Eruda.init();

            let deviceID = localStorage.getItem("deviceID");
            this.getAll("pole-data", "SASC_DB").then((res) => {
                this.poles = this.poleRecords = res.filter(
                    (pole) => pole.deviceId === deviceID
                );
                this.poleRecords = this.sortedPoles;
            });

        },

        computed: {
            sortedPoles: function () {
                let polesOrder = this.polesOrderAsc;
                let sortOn = this.sortParam;
                function compare(a, b) {
                    if (sortOn == "Date") {
                        if (!polesOrder) {
                            if (a.updatedOn < b.updatedOn) return -1;
                            if (a.updatedOn > b.updatedOn) return 1;
                            return 0;
                        } else {
                            if (a.updatedOn > b.updatedOn) return -1;
                            if (a.updatedOn < b.updatedOn) return 1;
                            return 0;
                        }
                    } else {
                        if (!polesOrder) {
                            if (a.poleId < b.poleId) return -1;
                            if (a.poleId > b.poleId) return 1;
                            return 0;
                        } else {
                            if (a.poleId > b.poleId) return -1;
                            if (a.poleId < b.poleId) return 1;
                            return 0;
                        }
                    }
                }

                return this.poleRecords.slice().sort(compare);
            },
        },

        watch: {
            searchString: function (pole) {
                setTimeout(() => this.searchPoles(pole), 800);
            },

            sortParam: function () {
                this.poleRecords = this.sortedPoles;
            },
        },

        methods: {
            getAll(store, db_name) {
                try {
                    return SimpleIDB.SimpleIDB.getAll(store, db_name);
                } catch (e) {
                    console.log(e);
                }
            },

            closePrompt() {
                this.popup = false;
            },

            confirmPrompt() {
                this.popup = false;
                this.deleteRecord();
            },

            openDashboard() {
                this.$router.replace("/dashboard");
            },

            searchPoles(str) {
                this.poleRecords = this.poles.filter((pole) => {
                    return pole.poleId.includes(str);
                });
            },

            sortPoles() {
                this.polesOrderAsc = !this.polesOrderAsc;
                this.poleRecords = this.sortedPoles;
            },

            formatUpdatedDate(updatedOn) {
                let updatedDate = new Date(updatedOn);
                return (
                    "" +
                    (updatedDate.getMonth() + 1 > 9 ? "" : "0") +
                    (updatedDate.getMonth() + 1) +
                    "/" +
                    (updatedDate.getDate() > 9 ? "" : "0") +
                    updatedDate.getDate() +
                    "/" +
                    updatedDate.getFullYear()
                );
            },

            showDeleteAlert(poleRecord) {
                this.selectedPole = poleRecord;
                this.popup = true;
            },

            deleteRecord() {
                this.deleteDamages();
            },

            deleteDamages() {
                if (this.selectedPole.damages.length > 0) {
                    let self = this;
                    this.selectedPole.damages.forEach((damage, index) => {
                        this.removeObject(damage.ID, "damage-data", "SASC_DB").then(
                            () => {
                                if (index == this.selectedPole.damages.length - 1) {
                                    self.deletePole();
                                }
                            }
                        );
                    });
                } else this.deletePole();
            },

            deletePole() {
                let self = this;
                this.removeObject(this.selectedPole.poleId, "pole-data", "SASC_DB").then(
                    () => {
                        self.refreshPoles();
                    }
                );
            },

            removeObject(key, store, db_name) {
                try {
                    return SimpleIDB.SimpleIDB.remove(key, store, db_name);
                } catch (e) {
                    console.log(e);
                }
            },

            refreshPoles() {
                this.getAll("pole-data", "SASC_DB").then((res) => {
                    this.poles = this.poleRecords = res;
                });
            },

            onCancel() {
                this.$router.replace("/dashboard");
            },

            launchEvaluation(poleRecord) {
                store.dispatch("setPoleRecord", JSON.stringify(poleRecord));
                this.$router.push("/evaluation");
            },
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .sort_a_1,
    .sort_d_1 {
        display: none;
    }

    .ascending {
        .sort_a_1 {
            display: inline-block;
        }
    }

    .descending {
        .sort_d_1 {
            display: inline-block;
        }
    }

    .imgSort {
        position: relative;
        display: flex;
    }

    .sortPI {
        border: 2px solid #1c3766;
        padding: 6px 10px 6px 5px;
        border-radius: 3px 0px 0px 3px;
        font-size: 14px;
        width: 88px;
        margin-bottom: 15px;
        height: 40px;
        color: #fff;
        margin-left: 10px;
        background: #1c3766;

        &:focus {
            outline: none;
        }
    }

    .dFlex {
        display: flex;

        .posrel {
            width: 100%;
        }
    }

    .arrow-up {
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid #fff;
        text-align: center;
        margin: 1px auto 5px auto;
        border-radius: 5px;
    }

    .arrow-down {
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid #ffffff;
        text-align: center;
        margin: 3px auto 1px auto;
        border-radius: 5px;
    }

    .ascending {
        .arrow-up {
            border-bottom: 8px solid #8799ab !important;
        }
    }

    .descending {
        .arrow-down {
            border-top: 8px solid #8799ab !important;
        }
    }

    .sortBtn {
        border: 2px solid #003468;
        background: #003468;
        display: inline-block;
        padding: 6px 6px;
        border-radius: 0px 3px 3px 0px;
        line-height: 32px;
        height: 40px;
        margin-left: 0px;
        text-align: center;
        min-width: 35px;
        border-left: 1px solid #5c6f91;

        img {
            width: 18px;
        }
    }

    .searchIcon {
        position: absolute;
        right: 12px;
        width: 20px;
        top: 10px;
        opacity: 0.6;
    }

    .searchBox {
        border: 2px solid #bfbfbf;
        padding: 6px 36px 6px 10px;
        border-radius: 3px;
        font-size: 16px;
        width: 100%;
        margin-bottom: 15px;
        height: 40px;
        color: #000;

        &::placeholder {
            color: #bfbfbf;
        }

        &:focus {
            outline: none;
        }
    }

    .posrel {
        position: relative;
    }

    .listInlinecst {
        text-align: left;
        list-style: none;
        padding-left: 0px;

        li {
            display: block;
            position: relative;
            border-bottom: 1px solid #d5d5d5;
            padding: 15px 40px 15px 0px;
            margin-bottom: 10px;

            img {
                width: 20px;
                cursor: pointer;
                position: absolute;
                right: 10px;
                top: 15px;
            }

            a {
                color: #000;
                text-decoration: none;
                font-size: 16px;
                display: block;

                .fntLastdate {
                    font-size: 12px;
                    margin-top: 5px;
                }

                span {
                    display: block;
                    word-break: break-all;
                }
            }
        }
    }

    .popUp {
        text-align: center;
        position: fixed;
        top: 0px;
        background: rgba(0, 0, 0, 0.2);
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .modalFooter {
        border-top: 1px solid #d5d5d5;
        padding: 10px 10px;
    }

    .popupText {
        padding: 20px 20px;
    }

    .popUpBox {
        background: #fff;
        border-radius: 8px;
        box-shadow: 0px 0px 5px #d2e4ff;
        padding: 0px;
        width: 300px;
        margin: auto;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .contactSection {
        margin: 12% 0px;
    }

    .btnRounded {
        background: #f1f1f1;
        border: 2px solid #f1f1f1;
        padding: 6px;
        border-radius: 50px;
        color: #2c95ff;
        min-width: 90px;
        cursor: pointer;
    }

    .btnRoundedFull {
        background: #2c95ff;
        border: 2px solid #2c95ff;
        padding: 6px;
        border-radius: 50px;
        color: #fff;
        min-width: 90px;
        cursor: pointer;
    }

    .mainPage {
        height: calc(100vh - 50px);
    }

    .headerLogo {
        img {
            width: 230px;
        }
    }

    .mainPageContent {
        margin: auto;
    }

    .listInline {
        list-style: none;
        padding-left: 0;
        font-size: 14px;
        margin: 1rem 0px;

        .listItem {
            display: inline-block;
            margin-right: 1rem;

            a {
                text-decoration: none;
            }

            .active {
                font-weight: 700;
            }
        }

        .listItem:last-child {
            margin-right: 0rem;
        }
    }

    .title1 {
        margin: 3% 0px;
        text-align: center;
        color: #003468;
        font-weight: 600;
        font-size: 20px;
    }

    .btndef {
        background: #ccc;
        border-radius: 5px;
        padding: 16px;
        color: #fff;
        font-size: 14px;
        border: 0px;
        cursor: pointer;
        font-weight: 600;
        width: 100%;
        margin: 18px 0px;
        pointer-events: none;
    }

    .btnblue {
        background: #003468;
        border-radius: 5px;
        padding: 16px;
        color: #fff;
        font-size: 14px;
        border: 0px;
        cursor: pointer;
        font-weight: 600;
        width: 100%;
        margin: 18px 0px;
        pointer-events: all !important;
    }

    .error {
        color: #f00;
        font-size: 12px;
    }

    .bottomFixed {
        position: fixed;
        bottom: 0px;
        width: 100%;
        text-align: center;
        background: #fff;
        z-index: 1;

        .listInline a {
            color: #003468;
        }
    }

    .backLink {
        color: #003468;
        font-size: 16px;
        text-decoration: none;
        font-weight: 600;
    }

    .contentPanel {
        width: 350px;
        text-align: center;
        margin: auto;
        padding-top: 2rem;
        padding-bottom: 50px;
    }

    .info1 {
        color: #003468;
        font-size: 14px;
        font-weight: 600;
        margin: 16px 0px;
    }

    .info2 {
        color: #2c95ff;
        font-size: 14px;
        font-weight: 600;
        margin: 8px 0px;
        text-decoration: none;
        display: inline-block;
    }
    //Tabs Only

    @media (min-width: 320px) {
        .maxHgt {
            height: calc(100vh - 380px);
            overflow: auto;
        }
    }

    @media (min-width: 375px) {
        .maxHgt {
            height: calc(100vh - 380px);
        }
    }

    @media (min-width: 1023.5px) {
        .maxHgt {
            height: calc(100vh - 385px);
        }
    }

    @media (min-width: 768px) {
        .contentPanel {
            padding-top: 5rem;
            width: 450px;
        }

        .maxHgt {
            height: calc(100vh - 450px);
        }

        .listInline {
            margin: 2rem 0px;
        }
        //   .headerLogo{
        //   img{
        //     width: 230px;
        //     height: 48px;
        //   }
        // }
    }
    //Desktop only
    @media (min-width: 1200px) {
        .sortPI {
            option {
                font-size: 18px;
            }
        }

        .listInline {
            margin: 1rem 0px;
        }

        .contentPanel {
            padding-top: 1rem;
        }

        .title1 {
            margin: 3% 0px;
        }
    }

    //Small mobile
    @media (max-width: 374.5px) {
        .contentPanel {
            width: 90% !important;
        }
    }
</style>
